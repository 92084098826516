<template>
    <div class="card card-primary" ref="prev" v-if="timeTracking.employees">
        <div class="card-header">
            <div class="card-title">
                Urlaub
            </div>
            <div class="card-tools">
            </div>
        </div>
        <div class="card-body text-xs">
            <div class="card card-outline card-primary">
                <div class="card-header">
                    <div class="card-title">
                        <ul class="pagination pagination-month justify-content-center" v-if="months.length > 0">
                            <li class="page-item" v-for="year in years" :key="year.year" :class="year.klasse"  @click="selectYear(year.year)">
                            <a class="page-link" href="#">
                                <h3 class="page-year">{{ year.year }}</h3>
                            </a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-tools">
                        <button class="btn btn-primary btn-sm mr-1" @click="createHoliday(null, null)" v-if="canEdit()">
                            <i class="fas fa-plus"></i> Erstellen
                        </button>
                        <button class="btn btn-secondary btn-sm" @click="getDays()">
                            <i class="fas fa-sync"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-8">

                        </div>
                        <div class="col-md-1 text-right text-lg">
                            <label for="download">Statistik</label>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <select name="" id="download" class="form-control form-control-sm" v-model="month_id">
                                    <option :value="null">-- Bitte wählen -- </option>
                                    <option v-for="month in months" :key="month.month" :value="month.month">{{month.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <button class="btn btn-sm btn-success" @click="downloadPDF()">
                                <i class="fas fa-download"></i>
                            </button>
                        </div>
                    </div>
                    <div class="table-responsive" style="max-height: 60vh; min-height: 60vh">
                        <table class="table table-borderless" style="overflow: auto">
                            <thead class="first" ref="content">
                                <!-- <tr>
                                    <th style="min-width: 150px;" class="bg-white" colspan="6"></th>
                                    <th v-for="date in dates" :key="date.day" class="bg-white text-xs" style="writing-mode: tb-rl; transform: rotate(-180deg);">
                                        {{date.official_holiday_name}}
                                    </th>
                                </tr> -->
                                <tr>
                                    <th style="min-width: 150px; border: none;" class="bg-white" colspan="6"></th>
                                    <th v-for="month in months" :key="month.month" :colspan="month.days" class="text-center month text-white text-md" style="border-right: solid white 3px;">
                                        <div class="row">
                                            <div class="col" v-for="index in 10" :key="index">
                                                {{month.name}}
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="table-primary second" style="border-left: none;">Mitarbeiter</th>
                                    <th class="table-primary sticky text-center" style="border-right: none;">
                                        UA
                                        <br>
                                        <i class="fas fa-question-circle" id="tooltip-ua"></i>
                                        <b-tooltip target="tooltip-ua" triggers="hover">
                                            Urlaubsanspruch <br>(+ Resturlaub aus dem Vorjahr)
                                        </b-tooltip>
                                    </th>
                                    <th class="table-primary sticky text-center" style="border-right: none;">
                                        UG
                                        <br>

                                        <i class="fas fa-question-circle" id="tooltip-ug"></i>
                                        <b-tooltip target="tooltip-ug" triggers="hover">
                                            Urlaub Genommen
                                        </b-tooltip>
                                    </th>
                                    <th class="table-primary sticky text-center" style="border-right: none;">
                                        RU
                                        <br>
                                        <i class="fas fa-question-circle" id="tooltip-ru"></i>
                                        <b-tooltip target="tooltip-ru" triggers="hover">
                                            Resturlaub
                                        </b-tooltip>
                                    </th>
                                    <th class="table-primary sticky text-center" style="border-right: none;">
                                        GU
                                        <br>
                                        <i class="fas fa-question-circle" id="tooltip-gu"></i>
                                        <b-tooltip target="tooltip-gu" triggers="hover">
                                            Geplanter Urlaub
                                        </b-tooltip>
                                    </th>
                                    <th class="table-primary sticky text-center" style="border-right: none;">
                                        K
                                        <br>
                                        <i class="fas fa-question-circle" id="tooltip-k"></i>
                                        <b-tooltip target="tooltip-k" triggers="hover">
                                            Krank
                                        </b-tooltip>
                                    </th>
                                    <th v-for="(date, index) in dates" :key="date.day" class="overflow text-center p-0 pb-2 pt-2"  :style="generateColorTitle(date, index)" v-b-tooltip.hover.top="generateHolidayTooltip(index) != '' ? generateHolidayTooltip(index) : null" :ref="generateDay(formatDate(date.date)).date == generateDay(currentDate).date ? 'yes':''" style="width: 3vh; max-width: 3vw;">
                                        <span style="color: #b57900;" class="p-0" v-b-popover.hover.top="createOpenSundayTag(date.date)">{{createOpenSundayTag(date.date)}} <br></span>
                                        {{generateDay(formatDate(date.date)).weekDay}} <br>
                                        {{generateDay(formatDate(date.date)).day}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-for="group in groups" :key="group.id">
                                <tr>
                                    <th style="min-width: 2.5vw;border: none;" class="table-light sticky text-center text-underlined p-0" colspan="6">
                                        <u>
                                            {{group.name}}
                                        </u>
                                    </th>
                                </tr>
                                <tr v-for="(employee, eIndex) in timeTracking.employees" :key="employee.id">
                                    <template v-if="group.id == employee.employee_groups_id">
                                        <th class="table-light" style="min-width: 8vw; border: none;">
                                            {{employee.first_name}} {{employee.last_name}}
                                        </th>
                                        <th style="min-width: 2.5vw;border: none;" class="table-light sticky text-center">
                                            {{calculateUrlaubabspruch(employee,eIndex)}}
                                            <!-- {{stats[eIndex].anspruch}} -->
                                        </th>
                                        <th style="min-width: 2.5vw;border: none;" class="table-light sticky text-center">
                                            <!-- Genommener Urlaub -->
                                            {{calculateUG(employee,eIndex)}}
                                            <!-- {{stats[eIndex].UG}} -->
                                        </th>
                                        <th style="min-width: 2.5vw;border: none;" class="table-light sticky text-center">
                                            {{calculateRestHoliday(employee,eIndex)}}
                                            <!-- {{stats[eIndex].RH}} -->
                                        </th>
                                        <th style="min-width: 2.5vw;border: none;" class="table-light sticky text-center">
                                            <!-- Geplanter Urlaub -->
                                            {{calculateGU(employee,eIndex)}}
                                            <!-- {{stats[eIndex].GU}} -->
                                        </th>
                                        <th style="min-width: 2.5vw;border: none;" class="table-light sticky text-center">
                                            {{calculateIllDays(employee,eIndex)}}
                                            <!-- {{stats[eIndex].illDays}} -->
                                        </th>
                                        <template v-for="(date, index) in dates">

                                            <template v-if="blockedDays[index] == false">
                                                <template v-if="isAbwesend(date.date, employee) == false">
                                                    <template v-if="date.holidays[eIndex] != null">
                                                        <template v-if="date.holidays[eIndex].state.name === 'confirmed'">
                                                            <template v-if="isSunday(date.date) == true">
                                                                <td
                                                                    class="hover text-center"
                                                                    style="background-color: #fffbc7"
                                                                    :key="date.date"
                                                                    @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                ></td>
                                                            </template>
                                                            <template v-else>
                                                                <td
                                                                    class="hover text-center"
                                                                    style="background-color: #fffbc7"
                                                                    :key="date.date"
                                                                    @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                >U</td>
                                                            </template>
                                                        </template>

                                                        <template v-else-if="date.holidays[eIndex].state.name === 'confirmed_unpaid'">
                                                            <template v-if="isSunday(date.date) == true">
                                                                <td
                                                                    class="hover text-center"
                                                                    style="background-color: #fffbc7"
                                                                    :key="date.date"
                                                                    @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                ></td>
                                                            </template>
                                                            <template v-else>
                                                                <td
                                                                    class="hover text-center"
                                                                    style="background-color: #fffbc7"
                                                                    :key="date.date"
                                                                    @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                >UU</td>
                                                            </template>
                                                        </template>

                                                        <template v-else-if="date.holidays[eIndex].state.name === 'requested'">
                                                            <template v-if="isSunday(date.date) == true">
                                                                <td
                                                                    class="hover text-center table-info"
                                                                    :key="date.date"
                                                                    @contextmenu.prevent="editWishDate(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                ></td>
                                                            </template>
                                                            <template v-else>
                                                                <td
                                                                    class="hover text-center table-info"
                                                                    :key="date.date"
                                                                    @contextmenu.prevent="editWishDate(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                >W</td>
                                                            </template>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'returned_request'">
                                                            <template v-if="isSunday(date.date) == true">
                                                                <td
                                                                    class="hover text-center"
                                                                    style="background-color: #e8a797"
                                                                    :key="date.date"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                ></td>
                                                            </template>
                                                            <template v-else>
                                                                <td
                                                                    class="hover text-center"
                                                                    style="background-color: #e8a797"
                                                                    :key="date.date"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                >G</td>
                                                            </template>
                                                        </template>

                                                        <template v-else-if="date.holidays[eIndex].state.name === 'ill'">
                                                            <template v-if="isSunday(date.date) == true">
                                                            <td
                                                                class="hover text-center"
                                                                style="background-color: #c78fff"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            ></td>
                                                            </template>
                                                            <template v-else>
                                                            <td
                                                                class="hover text-center"
                                                                style="background-color: #c78fff"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            >K</td>
                                                            </template>
                                                        </template>

                                                        <template v-else-if="date.holidays[eIndex].state.name === 'ill_unpaid'">
                                                            <template v-if="isSunday(date.date) == true">
                                                            <td
                                                                class="hover text-center"
                                                                style="background-color: #c78fff"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            ></td>
                                                            </template>
                                                            <template v-else>
                                                            <td
                                                                class="hover text-center"
                                                                style="background-color: #c78fff"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            >KU</td>
                                                            </template>
                                                        </template>

                                                        <template v-else-if="date.holidays[eIndex].state.name === 'absend'">
                                                            <td
                                                                class="hover text-center table-secondary"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            >A</td>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'absend_morning'">
                                                            <td
                                                                class="hover text-center table-secondary"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            >AV</td>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'absend_afternoon'">
                                                            <td
                                                                class="hover text-center table-secondary"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            >AN</td>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <td
                                                            class="hover text-center"
                                                            @contextmenu.prevent="rightClickActive == false ? createHoliday(date.date, employee) : rightClickActive = false; uncolorIt();selectedDates = []"
                                                            v-on:click.left="activateRightclick($event, employee.id)"
                                                            @mouseover="colorIt($event, employee.id, date.date)"
                                                            :key="date.date"
                                                            v-bind:attr="date.date"
                                                        ></td>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <td class="hover text-center table-secondary" :key="date.date">
                                                    </td>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <template v-if="isAbwesend(date.date, employee) == false">
                                                    <template v-if="date.holidays[eIndex] != null">
                                                        <template v-if="date.holidays[eIndex].state.name === 'confirmed'">
                                                            <template v-if="isSunday(date.date) == true">
                                                                <td
                                                                    class="hover text-center"
                                                                    style="background-color: #fffbc7"
                                                                    :key="date.date"
                                                                    @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                >X</td>
                                                            </template>
                                                            <template v-else>
                                                                <td
                                                                    class="hover text-center"
                                                                    style="background-color: #fffbc7"
                                                                    :key="date.date"
                                                                    @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                >X</td>
                                                            </template>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'requested'">
                                                            <td
                                                                class="hover text-center table-info"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editWishDate(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            >X</td>
                                                        </template>

                                                        <template v-else-if="date.holidays[eIndex].state.name === 'confirmed_unpaid'">
                                                            <template v-if="isSunday(date.date) == true">
                                                                <td
                                                                    class="hover text-center"
                                                                    style="background-color: #fffbc7"
                                                                    :key="date.date"
                                                                    @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                ></td>
                                                            </template>
                                                            <template v-else>
                                                                <td
                                                                    class="hover text-center"
                                                                    style="background-color: #fffbc7"
                                                                    :key="date.date"
                                                                    @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                >UU</td>
                                                            </template>
                                                        </template>

                                                        <template v-else-if="date.holidays[eIndex].state.name === 'ill'">
                                                            <td
                                                                class="hover text-center"
                                                                style="background-color: #c78fff" :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            >X</td>
                                                        </template>

                                                        <template v-else-if="date.holidays[eIndex].state.name === 'ill_unpaid'">
                                                            <template v-if="isSunday(date.date) == true">
                                                            <td
                                                                class="hover text-center"
                                                                style="background-color: #c78fff"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            ></td>
                                                            </template>
                                                            <template v-else>
                                                            <td
                                                                class="hover text-center"
                                                                style="background-color: #c78fff"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            >KU</td>
                                                            </template>
                                                        </template>

                                                        <template v-else-if="date.holidays[eIndex].state.name === 'absend'">
                                                            <td
                                                                class="hover text-center table-secondary"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            >X</td>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'absend_morning'">
                                                            <td
                                                                class="hover text-center table-secondary"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            >AV</td>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'absend_afternoon'">
                                                            <td
                                                                class="hover text-center table-secondary"
                                                                :key="date.date"
                                                                @contextmenu.prevent="editHolidays(date.holidays[eIndex])"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            >AN</td>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'returned_request'">
                                                            <td
                                                                class="hover text-center"
                                                                style="background-color: #e8a797"
                                                                :key="date.date"
                                                                v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                            >X</td>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <td
                                                            class="hover text-center"
                                                            @contextmenu.prevent="rightClickActive == false ? createHoliday(date.date, employee) : rightClickActive = false; uncolorIt();selectedDates = []"
                                                            v-on:click.left="activateRightclick($event, employee.id)"
                                                            @mouseover="colorIt($event, employee.id, date.date)"
                                                            :key="date.date"
                                                            v-bind:attr="date.date"
                                                        >X</td>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <td class="hover text-center table-secondary" :key="date.date">
                                                        X
                                                    </td>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-md-1 text-center">
                            <div style="background-color: #c78fff;padding: 2%">
                                K / KU
                            </div>
                            Krank / Unbezahlt
                        </div>
                        <div class="col-md-1 text-center">
                            <div style="background-color: #fffbc7;padding: 2%">
                                U / UU
                            </div>
                            Urlaub / Unbezahlt
                        </div>
                        <div class="col-md-1 text-center">
                            <div style="padding: 2%" class="table-info">
                                W
                            </div>
                            Anfrage
                        </div>
                        <div class="col-md-1 text-center">
                            <div style="background-color: #e8a797;padding: 2%">
                                G
                            </div>
                            Gegenvorschlag
                        </div>
                        <div class="col-md-1 text-center">
                            <div style="padding: 2%" class="table-secondary">
                                A
                            </div>
                            Abwesend
                        </div>
                        <div class="col-md-6 text-right text-lg">
                            <div class="row">
                                <div class="col">

                                </div>
                                <div class="col">
                                    V = Vormittags
                                </div>
                                <div class="col">
                                    N = Nachmittags
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="$auth.check('holidays.period.display')">
                <Holiday></Holiday>
            </template>
        </div>
        <b-modal
            id="createModal"
            :title="'Erstellen'"
            @ok.prevent="createHolidaySubmit"
            size="xl"
            :ok-title = "'Erstellen'"
            :cancel-title="'Abbrechen'"
            v-b-modal.modal-lg
            class="text-sm"
            @shown="form.errors.clear()"
        >
            <div class="row mb-3">
                <div class="col-md-6">
                    <label>Mitarbeiter*</label>
                </div>
                <div class="col-md-6">
                    <label>Typ*</label>
                </div>
                <div class="col-md-6">
                    <select class="form-control" v-model="form.id" :class="{'is-invalid': form.errors.has('id')}">
                        <option :value="null">-- Wähle Mitarbeiter --</option>
                        <option :value="employee.id" v-for="employee in timeTracking.employees" :key="employee.id">{{employee.first_name}} {{employee.last_name}}</option>
                    </select>
                    <has-error :form="form" field="id"></has-error>
                </div>
                <div class="col-md-6">
                    <select class="form-control" v-model="form.status_id" :class="{'is-invalid': form.errors.has('status_id')}">
                        <option :value="null">-- Wähle Typ --</option>
                        <option v-for="state in states" :key="state.id" :value="state.id">{{state.description}}</option>
                    </select>
                    <has-error :form="form" field="status_id"></has-error>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Von*: </label>
                </div>
                <div class="col-md-6">
                    <label>Bis*: </label>
                </div>
                <div class="col-md-6">
                    <input type="date" class="form-control" v-model="form.holiday_start_date" :class="{'is-invalid': form.errors.has('holiday_start_date')}">
                    <has-error :form="form" field="holiday_start_date"></has-error>
                </div>
                <div class="col-md-6">
                    <input type="date" class="form-control" v-model="form.holiday_stop_date" :class="{'is-invalid': form.errors.has('holiday_stop_date')}">
                    <has-error :form="form" field="holiday_stop_date"></has-error>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="editModal"
            :title="'Bearbeiten'"
            @hide="''"
            @ok.prevent="editHolidaySubmit"
            size="xl"
            :ok-title = "'Bestätigen'"
            :cancel-title="'Abbrechen'"
            v-b-modal.modal-lg
            class="text-sm"
            @cancel.prevent="deleteHoliday('editModal')"
            @shown="form.errors.clear()"
        >
            <div class="row mb-3">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Mitarbeiter*</label>
                        <select class="form-control" v-model="form.employee_id"  :class="{'is-invalid': form.errors.has('employee_id')}" disabled>
                            <option :value="null">-- Wähle Mitarbeiter --</option>
                            <option :value="employee.id" v-for="employee in timeTracking.employees" :key="employee.id">{{employee.first_name}} {{employee.last_name}}</option>
                        </select>
                        <has-error :form="form" field="employee_id"></has-error>
                    </div>
                </div>
                <div class="col-md-9 text-right text-lg">
                    {{form.created_at | formatDateTime}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Von*: </label>
                </div>
                <div class="col-md-6">
                    <label>Bis*: </label>
                </div>
                <div class="col-md-6">
                    <input type="date" class="form-control" v-model="form.holiday_start_date" :class="{'is-invalid': form.errors.has('holiday_start_date')}">
                    <has-error :form="form" field="holiday_start_date"></has-error>
                </div>
                <div class="col-md-6">
                    <input type="date" class="form-control" v-model="form.holiday_stop_date" :class="{'is-invalid': form.errors.has('holiday_stop_date')}">
                    <has-error :form="form" field="holiday_stop_date"></has-error>
                </div>
            </div>

            <template #modal-footer="{ cancel, hide, ok}">
                <b-button size="sm" variant="secondary" @click="hide()">
                    Schließen
                </b-button>
                <b-button size="sm" variant="danger" @click="cancel()">
                    Löschen
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Speichern
                </b-button>
            </template>
        </b-modal>
        <b-modal
            id="wishModal"
            :title="'Mitarbeiterwunsch'"
            @hide="''"
            @ok.prevent="editHolidayWishSubmit"
            size="xl"
            :ok-title = "'Bestätigen'"
            :cancel-title="'Abbrechen'"
            v-b-modal.modal-lg
            class="text-sm"
            @cancel="deleteHoliday('wishModal')"
            @shown="wishForm.errors.clear()"
        >
            <div class="row mb-3">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Mitarbeiter</label>
                        <select class="form-control" v-model="wishForm.employee_id" :class="{'is-invalid': wishForm.errors.has('employee_id')}" disabled>
                            <option :value="null">-- Wähle Mitarbeiter --</option>
                            <option :value="employee.id" v-for="employee in timeTracking.employees" :key="employee.id">{{employee.first_name}} {{employee.last_name}}</option>
                        </select>
                        <has-error :form="wishForm" field="employee_id"></has-error>
                    </div>
                </div>
                <div class="col-md-9 text-right text-lg">
                    {{wishForm.created_at | formatDateTime}}
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-md-6">
                    <label>Wunsch von*: </label>
                </div>
                <div class="col-md-6">
                    <label>Wunsch bis*: </label>
                </div>
                <div class="col-md-6">
                    <input type="date" class="form-control" v-model="wishForm.holiday_start_date" :class="{'is-invalid': wishForm.errors.has('holiday_start_date')}" disabled>
                    <has-error :form="wishForm" field="holiday_start_date"></has-error>
                </div>
                <div class="col-md-6">
                    <input type="date" class="form-control" v-model="wishForm.holiday_stop_date" :class="{'is-invalid': wishForm.errors.has('holiday_stop_date')}" disabled>
                    <has-error :form="wishForm" field="holiday_stop_date"></has-error>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Vorschlag von: </label>
                </div>
                <div class="col-md-6">
                    <label>Vorschlag bis: </label>
                </div>
                <div class="col-md-6">
                    <input type="date" class="form-control" v-model="wishForm.compare_start_date" :class="{'is-invalid': wishForm.errors.has('holiday_start_date')}">
                    <has-error :form="wishForm" field="holiday_start_date"></has-error>
                </div>
                <div class="col-md-6">
                    <input type="date" class="form-control" v-model="wishForm.compare_stop_date" :class="{'is-invalid': wishForm.errors.has('holiday_stop_date')}">
                    <has-error :form="wishForm" field="holiday_stop_date"></has-error>
                </div>
            </div>



            <template #modal-footer="{ cancel, hide, ok}">
                <b-button size="sm" variant="secondary" @click="hide()">
                    Schließen
                </b-button>
                <b-button size="sm" variant="danger" @click="cancel()">
                    Ablehnen
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Genehmigen / Vorschlagen
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import moment from 'moment'
import holidayMoment from 'holiday-de'
import Holiday from './components/Holiday.vue'
export default {
    name: "HolidayPlanningIndex",
    title: "Abwesenheitsplaner",
    components: {Holiday},
    data() {
        return {
            selectedEmployee: null,
            currentDate: moment(),
            showDate: moment(),
            months: [],
            years: [],
            states: [],


            blockedDays: [],
            holidays: [],
            dates: [],
            groups: [],
            stats: [],
            workplan: [],


            selectedYear: moment().format('YYYY'),


            form: new window.Form({
                id: null,
                employee_id: null,
                holiday_start_date: null,
                holiday_stop_date: null,
                status_id: null,
                state: {},
            }),
            wishForm: new window.Form({
                id: null,
                employee_id: null,
                holiday_start_date: null,
                holiday_stop_date: null,
                compare_start_date: null,
                compare_stop_date: null,
                status_id: null,
                state: {},
            }),

            timeTracking: {},

            currentEmployee: null,
            rightClickActive: false,
            selectedDates: [],

            month_id: moment().subtract(1, 'months').format('MM'),

            //EINSTELLUNGEN
            settings: {
                accessNextYear: 10, //Wann der Urlaubsplaner für nächstes Jahr freigeschaltet wird. (Monat: 1-12)
                firstShowedYear: 2, //Anzahl vergangender Jahre die angezeigt werden sollen
                lastShowedYear: 2, //Anzahl zukünftiger Jahrte die angezeigt werden sollen
            }
        }
    },
    watch: {
        'timeTracking.blocked_days': {
            handler() {
                this.generateBlockedDays();
            },
            deep: true,
        },
        'timeTracking.holidays': {
            handler() {
                this.generateHolidays();
            },
            deep: true,
        },
        'timeTracking.employees': {
            handler() {
                this.calculateAllEmployeeHolidays();
                this.timeTracking.employees.forEach(() => {
                    // this.calculateUrlaubabspruch(employee, eIndex);
                    // this.calculateRestHoliday(employee, eIndex);
                    // this.calculateIllDays(employee, eIndex);
                    // this.calculateUG(employee, eIndex);
                    // this.calculateGU(employee, eIndex);
                })
            },
            deep: true
        }
    },
    methods: {
        employeeIsWorking(date, employee_id) {
            let isWorking = false;
            this.workplan.forEach((week) => {
                let worker = week.workers.find(element => element.employee_id == employee_id && element.date == date);
                if(worker != null && worker != undefined)
                {
                    isWorking = true;
                }
            })

            return isWorking;
        },
        getEmployeeGroups()
        {
            this.axios
                .get("/employee/groups")
                .then((response) => {
                    this.groups = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim Laden der Mitarbeitergruppen ist etwas schief gelaufen'
                    })
                });
        },
        createOpenSundayTag(date)
        {
            let tag = "VO";
            this.timeTracking.open_sundays.forEach(store => {
                store.open_sundays.forEach(sunday => {
                    if(sunday.date == date)
                    {
                        tag += store.name.charAt(0) + "/";
                    }
                })
            });
            if(tag != "VO")
            {
                tag = tag.slice(0, -1,);
            } else
            {
                tag = null;
            }
            return tag;
        },
        dateSelected(target)
        {
            return this.selectedDates.includes(target);
        },
        activateRightclick(evt, employee, date) {
            if(this.rightClickActive)
            {
                this.rightClickActive = false;
                this.removeIt();
            } else
            {
                this.selectedEmployee = employee;
                this.selectedDates = [];
                this.rightClickActive = true;
                this.colorIt(evt, employee, date);
            }
        },
        colorIt(evt, employee)
        {

            let klass = 'table-active';
            if(this.rightClickActive)
            {
                if(this.selectedEmployee == employee)
                {
                    if(!this.dateSelected(evt.target))
                    {
                        if(this.selectedDates.length == 0)
                        {
                            this.selectedDates.push(evt.target);
                            evt.target.classList.add(klass);
                        } else
                        {
                            let isNextTo = false;
                            this.selectedDates.forEach(element => {
                                let compare = moment(this.formatDate(element.getAttribute('attr')));
                                if(moment(this.formatDate(evt.target.getAttribute('attr'))).subtract(1, 'days').format('YYYY-MM-DD') == compare.format('YYYY-MM-DD')
                                    || moment(this.formatDate(evt.target.getAttribute('attr'))).add(1, 'days').format('YYYY-MM-DD') == compare.format('YYYY-MM-DD'))
                                {
                                    isNextTo = true;
                                }
                            });
                            if(isNextTo)
                            {
                                this.selectedDates.push(evt.target);
                                evt.target.classList.add(klass);
                            }

                        }
                    }
                }
            }
        },
        uncolorIt()
        {
            this.selectedDates.forEach(element => {
                element.classList.remove('table-active');
            })
        },
        removeIt()
        {
            this.selectedDates.forEach(element => {
                element.classList.remove('table-active');
            })

            let max = Math.max(
                ...this.selectedDates.map(element => {
                    return moment(this.formatDate(element.getAttribute('attr')));
                })
            );
            let min = Math.min(
                ...this.selectedDates.map(element => {
                    return moment(this.formatDate(element.getAttribute('attr')));
                })
            );

            this.createHolidayStartEnd(moment(min).format('YYYY-MM-DD'), moment(max).format('YYYY-MM-DD'), this.selectedEmployee);

            this.selectedDates = [];
        },

        /*
            Generate Calendar
        */
        calculateDays()
        {
            //Generate Days
            let days = []
            var a = moment({
                years: this.selectedYear,
                months: 0,
                date: 1,
            });
            var b = moment({
                years: this.selectedYear,
                months: 11,
                date: 31,
            });

            holidayMoment.setState('nw');
            // If you want an inclusive end date (fully-closed interval)
            for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'days')) {
                days.push({
                    date: m.format('YYYY-MM-DD'),
                    official_Holiday: holidayMoment.isHoliday(m) == false ? false : true,
                    official_holiday_name: holidayMoment.isHoliday(m) == false ? "" : holidayMoment.isHoliday(m)
                })
            }
            this.dates = days;

            //Generate Months
            var year = this.selectedYear;
            this.months = [];
            for(var i=1; i<=12; i++){
                var date = moment({
                    year: year,
                    month: i-1,
                    day: 1
                });
                this.months.push({
                    year: year,
                    week: date.week(),
                    name: date.locale('de').format('MMMM'),
                    days: date.daysInMonth(),
                    month: date.format('MM'),
                });
            }
        },

        //Year Navigation
        generateYears()
        {
            this.years = [];
            var currYear = parseInt(this.currentDate.format('YYYY'));
            var currMonth = parseInt(this.currentDate.format('M'));

            for(var i = -1 * this.settings.firstShowedYear; i <= this.settings.lastShowedYear; i += 1)
            {
                var klasse = "";


                if(currYear+i > currYear)
                {
                    if(currMonth >= this.settings.accessNextYear && currYear+1 == currYear+i)
                    {
                        //NICHTS
                    } else
                    {
                        klasse += ' disabled';
                    }
                }


                if(currYear+i == this.selectedYear)
                {
                    klasse += ' active';
                }
                this.years.push({
                    year: currYear+i,
                    klasse,
                });
            }
        },

        //Chose a Year
        selectYear(year)
        {
            var currYear = parseInt(this.currentDate.format('YYYY'));
            var currMonth = parseInt(this.currentDate.format('M'));
            if(currYear < year)
            {
                if(currMonth >= this.settings.accessNextYear && currYear+1 == year)
                {
                    //NICHTS
                } else
                {
                    return;
                }
            }
            this.selectedYear = year;
            this.getDays();
            this.calculateDays();
            this.generateYears();

            this.uncolorIt();
            this.selectedDates = [];
            this.rightClickActive = false;
        },








        /*

            Calculate all Informations for the employee

        */


        calculateUrlaubabspruch(employee, index)
        {
            let days = employee.holiday_days;
            if(moment().format('YYYY') == this.selectedYear)
            {
                days +=  employee.rest_holidays;
            }

            let start_date;
            if(employee.start_date != null)
            {
                if(moment(this.formatDate(employee.start_date)).format('YYYY') == this.selectedYear)
                {
                    start_date = moment(employee.start_date);
                } else
                {
                    start_date = moment({
                        year: this.selectedYear,
                        month: 0,
                        day: 1
                    });
                }
            } else
            {
                start_date = moment({
                    year: this.selectedYear,
                    month: 0,
                    day: 1
                });
            }
            // let start_year = moment(this.selectedYear + "-01-01");
            let start_year = moment({
                                        year: this.selectedYear,
                                        month: 0,
                                        date: 1,
                                    });

            var currYear = parseInt(moment().format('YYYY'));
            let yearDays = employee.holiday_days / (moment({year: this.selectedYear, month: 11, date: 31}).diff(moment({year: this.selectedYear, month: 0, date: 1}), 'days', [])+1);

            // if(moment(employee.start_date).isBetween(moment(currYear + '-01-01'), moment(currYear + '-12-31')))
            if(moment(employee.start_date).isBetween(moment({year: currYear, month: 0, date: 1}), moment({year: currYear, month: 11, date: 31})))
            {
                days -= yearDays * start_year.diff(start_date, 'days') * -1;
            }

            if(employee.stop_date != null)
            {
                if(moment(employee.stop_date).isBetween(moment({year: currYear, month: 0, date: 1}), moment({year: currYear, month: 11, date: 31})))
                {
                    days -= yearDays * (moment(this.formatDate(employee.stop_date)).diff(moment({year: this.selectedYear, month: 11, date: 31}), 'days', []) * -1);
                }
            }

            if(employee.inactive_start != null && employee.inactive_stop != null)
            {
                days -= yearDays * (moment(this.formatDate(employee.inactive_start)).diff(moment(this.formatDate(employee.inactive_stop)), 'days', []) * -1);
            }
            return Math.round(days);
        },
        calculateRestHoliday(employee, index)
        {
            let days = this.calculateUrlaubabspruch(employee, index);

            // days = days / (moment(this.selectedYear + '-12-31').diff(moment(this.selectedYear + '-01-01'), 'days')+1) * start_year.diff(start_date, 'days') * -1;

            employee.holidays.forEach(element => {
                if(moment(this.formatDate(element.start_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})) || moment(this.formatDate(element.end_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})))
                {
                    if(element.state.name == 'confirmed' || element.state.name == 'requested' || element.state.name == 'returned_request')
                    {
                        let dates = this.getDates(element.start_date, element.end_date);
                        dates.forEach(date => {
                            let weekday = date.locale("de").format("dddd");
                            let official_holiday = date.locale("de").format("Y-MM-DD");
                            
                            if(date.isSameOrBefore(moment()))
                            {
                                if(this.dates.find(item => item.date == official_holiday))
                                {
                                    if(weekday != 'Sonntag' && this.dates.find(item => item.date == official_holiday).official_Holiday == false)
                                    {
                                        days -= 1;
                                    }
                                }
                            }
                        });
                    }
                }
            });
            this.stats[index].RH = Math.round(days);
            return Math.round(days);
        },
        calculateIllDays(employee, index)
        {
            let days = 0;
            employee.holidays.forEach(element => {
                if(moment(this.formatDate(element.start_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})) || moment(this.formatDate(element.end_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})))
                {
                    if(element.state.name == 'ill')
                    {
                        let dates = this.getDates(element.start_date, element.end_date);
                        dates.forEach(date => {
                            let official_holiday = date.locale("de").format("Y-MM-DD");
                            if(this.dates.find(item => item.date == official_holiday) != undefined)
                            {
                                if(this.dates.find(item => item.date == official_holiday).official_Holiday == false)
                                {
                                    days += 1;
                                }
                            }
                        });
                    }
                }
            });
            this.stats[index].illDays = Math.round(days);
            return Math.round(days);
        },
        calculateUG(employee, index)
        {
            let days = 0;
            employee.holidays.forEach(element => {
                if(element.state.name == 'confirmed')
                {
                    if(moment(this.formatDate(element.start_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})) || moment(this.formatDate(element.end_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})))
                    {
                        let dates = this.getDates(element.start_date, element.end_date);
                        dates.forEach(date => {
                            let weekday = date.locale("de").format("dddd");
                            let official_holiday = date.locale("de").format("Y-MM-DD");
                            if(this.dates.find(item => item.date == official_holiday) != undefined) {
                                if(weekday != 'Sonntag' && this.dates.find(item => item.date == official_holiday).official_Holiday == false)
                                {
                                    if(date.isSameOrBefore(moment()))
                                    {
                                        days += 1;
                                    }
                                }
                            }
                        });
                    }
                }
            });
            this.stats[index].UG = Math.round(days);
            return Math.round(days);
        },
        calculateGU(employee, index)
        {
            let days = 0;
            employee.holidays.forEach(element => {
                if(element.state.name == 'confirmed')
                {
                    if(moment(this.formatDate(element.start_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})) || moment(this.formatDate(element.end_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})))
                    {
                        let dates = this.getDates(element.start_date, element.end_date);
                        dates.forEach(date => {
                            let weekday = date.locale("de").format("dddd");
                            let official_holiday = date.locale("de").format("Y-MM-DD");
                            if(this.dates.find(item => item.date == official_holiday) != undefined)
                            {
                                if(weekday != 'Sonntag' && this.dates.find(item => item.date == official_holiday).official_Holiday == false)
                                {
                                    if(date.isAfter(moment()))
                                    {
                                        days += 1;
                                    }
                                }
                            }
                        });
                    }
                }
            });
            this.stats[index].GU = Math.round(days);
            return Math.round(days);
        },


        /*

            Some helpers

        */
        getDates(startDate, stopDate) {
            let dates = [];
            let currentDate = moment(this.formatDate(startDate));
            while(currentDate.isSameOrBefore(this.formatDate(stopDate)))
            {
                dates.push(moment(currentDate));
                currentDate = currentDate.add(1, 'days');
            }
            return dates;
        },
        formatDate(date)
        {
            let element = date.split(/[\s-:]+/);
            return {
                year: parseInt(element[0]),
                month: parseInt(element[1])-1,
                date: parseInt(element[2]),
            }
        },




        //Colorize table header
        generateColorTitle(date, index)
        {

            let day = moment(this.formatDate(date.date)).locale("de").format("dddd");

            let colors_new = [];
            for(let i = 0; i < this.holidays[index].length; i += 1)
            {
                colors_new.push(this.holidays[index][i].color);
            }

            if(date.official_Holiday == true)
            {
                //FEIERTAGE
                colors_new.push('#f5c6cb');
                return 'background-color: #f5c6cb';
            } else if(day == "Sonntag")
            {
                //SONNTAGE
                colors_new.push('#ffeeba');
                return 'background-color: #ffeeba';
            }

            if(colors_new.length == 0)
            {
                return "";
            }

            let string = "background: linear-gradient(to bottom,";
            for(let i = 0; i < colors_new.length; i += 1)
            {
                if(i == 0)
                {
                    string += colors_new[i] + " 0% " + (100/colors_new.length) + "%";
                } else
                {
                    string += ", " + colors_new[i] + " " +(100/colors_new.length)*(i) + "% " + (100/colors_new.length)*(i+1)+ "%";
                }
            }
            string += ");"
            return string;
        },
        generateHolidayTooltip(index) {
            let string = "";
            this.holidays[index].forEach(element => {
                string += element.type + " / ";
            });
            string += this.dates[index].official_holiday_name;
            string = string.slice(0, -1);
            string = string.slice(0, -1);
            string = string.slice(0, -1);

            return string;
        },

        //check employee has holidays on these day
        hasHoliday(employee, date)
        {
            let d = null;
            employee.holidays.forEach(element => {
                let g = moment(date).isBetween(moment(this.formatDate(element.start_date)), moment(this.formatDate(element.end_date)), 'days', []);
                if(g == true)
                {
                    d = element;
                }
            });


            return d;
        },
        isSunday(date)
        {
            let day = moment(this.formatDate(date)).locale("de").format("dddd");
            let official_Holiday = this.dates.find(item => item.date == moment(this.formatDate(date)).locale("de").format('Y-MM-DD')).official_Holiday;
            if(official_Holiday == true)
            {
                return true;
            } else if(day == "Sonntag")
            {
                return true;
            }
            return false;
        },

        //Prüfen ob dort irgendwelche Ferien sind
        isHolidays(date)
        {
            let d = null;
            this.timeTracking.holidays.forEach(element => {
                let g = moment(this.formatDate(date)).isBetween(moment(this.formatDate(element.start_date)), moment(this.formatDate(element.end_date)), 'days', []);
                if(g == true)
                {

                    d = element;
                }
            });
            return d;
        },
        isBlockedDay(date)
        {
            let d = false;
            this.timeTracking.blocked_days.forEach(element => {
                let g = moment(this.formatDate(date)).isBetween(moment(this.formatDate(element.start_date)), moment(this.formatDate(element.end_date)), 'days', []);
                if(g == true)
                {

                    d = element;
                }
            });
            return d;
        },

        //Formatting date
        generateDay(date)
        {
            return {'date': moment(date).format('YYYY-MM-DD'),'weekDay': moment(date).locale('de').format('dd') , 'day': moment(date).locale('de').format('DD')};
        },


        //Create holidays for a employee
        createHoliday(date, employee)
        {
            if(!this.canEdit())
                return;
            this.openModal('createModal');
            if(employee != null)
                this.form.fill(employee);
            else
            {
                this.form.employee_id = null;
                this.form.id = null;
            }
            this.form.holiday_start_date = date;
            this.form.holiday_stop_date = null;
            this.form.status_id = null;
        },
        //Create holidays for a employee
        createHolidayStartEnd(date, dateEnd, employee)
        {
            if(!this.canEdit())
                return;
            this.openModal('createModal');
            if(employee != null)
                this.form.fill(this.timeTracking.employees.find(element => element.id == employee));
            else
            {
                this.form.employee_id = null;
                this.form.id = null;
            }
            this.form.holiday_start_date = date;
            this.form.holiday_stop_date = dateEnd;
            this.form.status_id = null;
        },
        createHolidaySubmit()
        {
            this.form
                .post('holiday-tracking')
                .then((response) => {
                    let index = this.timeTracking.employees.findIndex(element => element.id == response.data.data.id);
                    this.closeModal("createModal")
                    this.timeTracking.employees.splice(index, 1, response.data.data);
                    this.$swal({
                        icon: "success",
                        title: "Eintrag wurde erstellt.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                });
        },


        //Change employee holidays
        editHolidays(timeRange)
        {
            if(!this.canEdit())
                return;
            this.openModal('editModal');
            this.form.state = timeRange.state;
            this.form.id = timeRange.id;
            this.form.employee_id = timeRange.employee_id;
            this.form.holiday_start_date = timeRange.start_date;
            this.form.holiday_stop_date = timeRange.end_date;
            this.form.status_id = timeRange.status_id;
            this.form.created_at = timeRange.created_at;
        },

        editHolidaySubmit()
        {
            this.form
                .put('/holiday-tracking/' + this.form.id)
                .then((response) => {
                    let index = this.timeTracking.employees.findIndex(element => element.id == response.data.data.id);
                    this.closeModal("editModal")
                    this.timeTracking.employees.splice(index, 1, response.data.data);
                    this.$swal({
                        icon: "success",
                        title: "Eintrag wurde bearbeitet.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {

                });
        },
        editWishDate(timeRange)
        {
            if(!this.canEdit())
                return;
            this.openModal('wishModal');
            this.wishForm.state = timeRange.state;
            this.wishForm.id = timeRange.id;
            this.wishForm.employee_id = timeRange.employee_id;
            this.wishForm.created_at = timeRange.created_at

            this.wishForm.holiday_start_date = timeRange.start_date;
            this.wishForm.compare_start_date = timeRange.start_date;

            this.wishForm.holiday_stop_date = timeRange.end_date;
            this.wishForm.compare_stop_date = timeRange.end_date;

            this.wishForm.status_id = timeRange.status_id
            this.wishForm.comment = "";
        },
        editHolidayWishSubmit()
        {
            this.wishForm.put('/holiday-tracking/' + this.wishForm.id)
                .then((response) => {
                    let index = this.timeTracking.employees.findIndex(element => element.id == response.data.data.id);
                    this.closeModal("wishModal")
                    this.timeTracking.employees.splice(index, 1, response.data.data);
                    this.$swal({
                        icon: "success",
                        title: "Eintrag wurde bearbeitet.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                });
        },


        deleteHoliday(name)
        {
            if(name == "wishModal")
            {
                this.closeModal(name);
                this.$swal({
                    icon: "warning",
                    title: "Möchtest du den Urlaub wirklich Ablehnen",
                    input: 'textarea',
                    inputLabel: 'Grund der Ablehnung',
                    inputPlaceholder: 'Gib hier den Grund ein...',
                    showCancelButton: true,
                    cancelButtonText: "Abbrechen",
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: "Ablehnen!",
                })
                .then((result) => {
                    if(result.isConfirmed)
                    {
                        //Send request to the Server
                        this.wishForm.comment = result.value;
                        this.wishForm
                            .delete('/holiday-tracking/' + this.wishForm.id)
                            .then((response) => {
                                let index = this.timeTracking.employees.findIndex(element => element.id == response.data.data.id);
                                this.closeModal(name)
                                this.timeTracking.employees.splice(index, 1, response.data.data);
                                this.$swal({
                                    icon: "success",
                                    title: "Der wunsch Urlaub wurde abgelehnt.",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            })
                    } else
                    {
                        this.openModal(name);
                    }
                });
            } else if(name == "editModal")
            {
                this.$swal({
                    title: "Möchtest du den Urlaub wirklich löschen",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, löschen!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.form
                            .delete('/holiday-tracking/' + this.form.id)
                            .then((response) => {
                                let index = this.timeTracking.employees.findIndex(element => element.id == response.data.data.id);
                                this.closeModal(name)
                                this.timeTracking.employees.splice(index, 1, response.data.data);
                                this.$swal({
                                    icon: "success",
                                    title: "Der ausgewählte Urlaub wurde gelöscht.",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            })
                    }
                });
            }
        },
        canEdit()
        {
            return parseInt(this.currentDate.format('YYYY')) <= this.selectedYear;
        },

        openModal(name)
        {
            this.$bvModal.show(name);
        },
        closeModal(name)
        {
            this.$bvModal.hide(name);
        },

        generateHolidays()
        {
            this.holidays = [];
            this.dates.forEach((element, index) => {
                this.holidays[index] = [];
                // this.holidays.push(this.isHolidays(element.date));
                this.timeTracking.holidays.forEach(holiday => {
                    let between = moment(this.formatDate(element.date)).isBetween(moment(this.formatDate(holiday.start_date)), moment(this.formatDate(holiday.end_date)), 'days', []);
                    if(between == true)
                    {
                        this.holidays[index].push(holiday);
                    }
                })
            });
        },

        //Prüfen ob dort ein blockierter Tag ist

        generateBlockedDays()
        {
            this.blockedDays = [];
            this.dates.forEach((element) => {
                this.blockedDays.push(this.isBlockedDay(element.date));
            });
        },
        isAbwesend(date, employee)
        {
            if(employee.inactive_start != null && employee.inactive_stop != null)
            {
                let date1 = moment(this.formatDate(employee.inactive_start));
                let date2 = moment(this.formatDate(employee.inactive_stop));
                let between = moment(this.formatDate(date)).isBetween(date1, date2, 'days', []);
                return between;
            }
            return false;
        },













        getStates()
        {
            this.axios
                .get("/states/holidays")
                .then((response) => {
                    this.states = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                })
        },

        calculateAllEmployeeHolidays()
        {
            this.dates.forEach((date, index) => {
                this.dates[index].holidays = [];
                this.timeTracking.employees.forEach((employee, eIndex) => {
                    this.dates[index].holidays[eIndex] = this.hasHoliday(employee, date.date);
                    // let g = moment(this.formatDate(date)).isBetween(moment(this.formatDate(employee.)), moment(this.formatDate(element.end_date)), 'days', []);
                })
            })
        },
        getDays()
        {
            this.axios
                .get("/holiday-tracking", {
                    params: {
                        year: this.selectedYear
                    }
                })
                .then((response) => {
                    let currYear = moment().format('YYYY');
                    this.timeTracking = response.data.data;
                    if(moment({year: this.selectedYear, month: 0, date: 1}).format('YYYY') == currYear)
                    {
                        this.$nextTick(()=>{
                            this.$refs.yes[0].scrollIntoView({ block: 'center', behavior: 'smooth', inline: 'center' });
                        });
                    }
                    this.calculateAllEmployeeHolidays();
                    this.timeTracking.employees.forEach((employee, index) => {
                        this.stats[index] = {
                            name: employee.first_name + " " + employee.last_name,
                            UG: 0,
                            anspruch: 0,
                            illDays: 0,
                            GU: 0,
                        };
                    })
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        downloadPDF()
        {
            this.axios
                .get("/holiday-trackings/statistics", {
                    responseType: 'arraybuffer',
                    params: {
                        month: this.month_id,
                        year: this.selectedYear,
                    }
                }).then((response) => {
                    this.downloadFile(response);
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Statistik ist etwas schief gelaufen'
                    })
                })
        },
        downloadFile(response){
            var newBlob = new Blob([response.data], {type: 'application/pdf'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = 'Abwesenheitskalendar_Statistik- ' + moment().locale('de').month(this.month_id-1).format('MMMM') +' 2022.pdf';
            link.click()
            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            }, 100)
        },
        getWorkplanWeeks()
        {
            this.axios
                .get("/private/workplan-weeks", {
                    params: {
                        filter_active: true
                    }
                })
                .then((response) => {
                    this.workplan = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Arbeitszeiten ist etwas schief gelaufen'
                    })
                })
        }
    },
    mounted() {
        this.selectedYear = parseInt(this.currentDate.format('YYYY'));


        this.generateYears();
        this.getDays();
        this.calculateDays();
        this.getStates();
        this.getEmployeeGroups();
        this.getWorkplanWeeks();

    }
}
</script>

<style scoped>
.table th:first-child,
.table td:first-child {
  position: sticky;
  left: 0;
  z-index: 5;
}
.sticky:nth-child(2) {
  position: sticky;
  left: 8vw;
  z-index: 5;
}
.sticky:nth-child(3) {
  position: sticky;
  left: 10.5vw;
  z-index: 5;
}
.sticky:nth-child(4) {
  position: sticky;
  left: 13vw;
  z-index: 5;
}
.sticky:nth-child(5) {
  position: sticky;
  left: 15.5vw;
  z-index: 5;
}
.sticky:nth-child(6) {
  position: sticky;
  left: 18vw;
  z-index: 5;
}


.first { position: sticky; top: 0; z-index: 99; background: white;}
.second { position: sticky; top: 3.8vh; z-index: 2; }


.date {
    width: 20px;
}
.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > thead > tr > td{
    border: 0.1px solid ;
    border-color: rgba(0, 0, 0, 0.1);
}
.hover:hover{
    background: #c7ffff !important;
}
table tr td{
    cursor: pointer;
}

.overflow {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 2.5vw !important;
    min-width: 2.5vw !important;
    width: 2.5vw !important;
}
</style>
<style>
.month:nth-child(odd) {
    background-color: #2e3d75 !important;
}
.month:nth-child(even) {
    background-color: #6579c9 !important;
}
</style>